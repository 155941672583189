<template>
	<v-container
		fluid
		class="white-background main-detail-page"
		:class="`${customClass} ${!fullscreenClass ? 'fullscreen' : ''}`"
		id="detail-template-id"
	>
		<template v-if="$slots['header-topbar']">
			<slot name="header-topbar"></slot>
		</template>
		<v-layout>
			<template v-if="$slots['sidebar-detail']">
				<div class="border-right py-3 position-relative">
					<div class="sidebar--detail pr-0" v-if="sidebarActive">
						<div class="mr-3">
							<slot name="sidebar-detail"></slot>
						</div>
					</div>
					<v-btn
						color="blue"
						icon
						style="position: absolute; top: 0px; right: -15px; z-index: 1"
						min-width="30"
						width="30"
						@click="toggleSideBar"
					>
						<v-icon class="white blue--text text--darken-4" :class="{ 'mdi-flip-h': !sidebarActive }"
							>mdi-menu-open</v-icon
						>
					</v-btn>
				</div>
			</template>
			<v-col
				class="main-section--detail py-0 pl-4 pr-0"
				:class="{ 'full-width': !sidebarActive || !$slots['sidebar-detail'] }"
			>
				<template v-if="$slots['header-filter']">
					<slot name="header-filter"></slot>
				</template>
				<div class="d-flex">
					<template>
						<slot name="sidebar-listing"></slot>
					</template>
					<v-flex class="details-section">
						<div class="body-content-wrapper">
							<slot name="body"></slot>
						</div>
						<slot name="footer"></slot>
					</v-flex>
				</div>
			</v-col>
		</v-layout>
	</v-container>
</template>

<script>
export default {
	props: {
		customClass: {
			type: String,
			default: null,
		},
		fullscreenClass: {
			type: Boolean,
			default: null,
		},
		customSecondClass: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			observer: null,
			pageLoading: false,
			dataLoading: false,
			headerTitleWidth: null,
			headerActionWidth: null,
			sidebarActive: true,
		};
	},
	methods: {
		toggleSideBar() {
			this.sidebarActive = !this.sidebarActive;
		},
		adjustActionWidth() {
			const headerActionElem = this.$refs["headerAction"];
			const headerTitleElem = this.$refs["headerTitle"];
			if (headerActionElem && headerTitleElem) {
				this.$nextTick(() => {
					const actionWidth = headerActionElem.clientWidth;
					headerTitleElem.style.maxWidth = `calc(100% - ${actionWidth + 50}px)`;
				});
			}
		},
		callback(mutationList) {
			const _this = this;
			for (const mutation of mutationList) {
				_this.adjustActionWidth(mutation);
			}
		},
		domUpdateObserver() {
			const _this = this;
			const targetNode = document.getElementById("detail-template-id");
			const config = { attributes: true, childList: true, subtree: true };
			const observer = new MutationObserver((mutationList, observer) =>
				_this.callback(mutationList, observer)
			);
			observer.observe(targetNode, config);
			_this.observer = observer;
		},
	},
	beforeDestroy() {
		window.removeEventListener("resize", () => this.adjustActionWidth());
		if (this.observer) {
			this.observer.disconnect();
		}
	},
	mounted() {
		window.addEventListener("resize", () => {
			this.adjustActionWidth();
		});
		// this.adjustActionWidth();
		this.$nextTick(() => {
			this.domUpdateObserver();
		});
	},
};
</script>
<style lang="scss" scoped>
.sidebar--detail {
	width: 320px;
	max-width: 320px;
	// height: calc(100vh - 95px);
	height: calc(100vh - 192px);
	overflow: auto;
}
.main-section--detail {
	flex-grow: 1;
	width: calc(100% - 320px);
	max-width: calc(100% - 320px);
	&.full-width {
		width: 100%;
		max-width: 100%;
	}
	.body-content-wrapper {
		// height: calc(100vh - 104px);
		height: calc(100vh - 180px);
		overflow: auto;
	}
}
.sticky-top-52 {
	position: sticky;
	top: 50px;
	z-index: 9;
	background: #fff;
}
</style>
