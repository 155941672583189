<template>
	<div v-if="drawer">
		<v-navigation-drawer
			v-model="drawer"
			fixed
			width="600"
			right
			temporary
			stateless
			style="z-index: 200"
		>
			<div class="drawer-wrapper">
				<v-flex class="py-4 px-5 border-bottom drawer-header">
					<div class="font-level-3-bold">{{ titleStatus }} Contact</div>
				</v-flex>
				<v-form
					ref="form"
					id="form"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="update_or_create"
				>
					<div class="drawer-content pt-5 px-5">
						<v-row>
							<v-col md="3" class="mt-2 text-end">
								<label class="fw-500 required">Contact Name </label>
							</v-col>
							<v-col md="9" class="mb-3">
								<div class="d-flex">
									<AutoCompleteInput
										style="max-width: 80px; min-width: 80px; overflow: hidden"
										dense
										id="reminder-type"
										hide-details
										placeholder="Salutation"
										:items="salutation_type_items"
										:disabled="pageLoading"
										:loading="pageLoading"
										item-text="text"
										v-model="contact.salutation"
										item-value="value"
										v-on:change="get_reminder_values()"
									>
									</AutoCompleteInput>

									<v-text-field
										depressed
										hide-details
										outlined
										placeholder="Name"
										v-model="contact.contact_name"
										:disabled="pageLoading"
										:loading="pageLoading"
										:rules="[
											vrules.required(contact.contact_name, 'Name'),
											vrules.maxLength(contact.contact_name, 'Name', 30),
										]"
										:class="{
											required: !contact.contact_name,
										}"
									></v-text-field>
								</div>
							</v-col>

							<v-col md="3" class="mt-2 text-end">
								<label class="fw-500">Contact Mobile</label>
							</v-col>
							<v-col md="9">
								<PhoneTemplate
									:disabled="pageLoading"
									id="mobile_Contact"
									class-name="mt-0"
									v-model="contact.phone_number"
									placeholder="Mobile Number"
									:loading="pageLoading"
									@loading="($event) => (pageLoading = $event)"
									@blur="validatePhone(cindex)"
									@validity="phoneValid($event, cindex, 'mobile')"
									@clear="contact.phone_number = null"
								></PhoneTemplate>
							</v-col>

							<!-- Landline Field -->
							<v-col md="3" class="mt-2 text-end">
								<label class="fw-500">Contact Landline</label>
							</v-col>
							<v-col md="9">
								<div class="d-flex align-center">
									<PhoneTemplate
										:disabled="pageLoading"
										id="landline"
										class-name="mt-0"
										placeholder="Landline"
										v-model="contact.land_line_number"
										:loading="pageLoading"
										@loading="($event) => (pageLoading = $event)"
										@blur="validatePhone(cindex)"
										@validity="phoneValid($event, cindex, 'land_line_number')"
										@clear="contact.land_line_number = null"
									></PhoneTemplate>
									<v-icon class="mb-3" color="blue" @click="addContactField">mdi-plus-circle-outline</v-icon>
								</div>
							</v-col>

							<!-- DID Field -->
							<v-col v-if="showDID || contact.did_number != null" md="3" class="mt-2 text-end">
								<label class="fw-500">Contact DID</label>
							</v-col>
							<v-col v-if="showDID | (contact.did_number != null)" md="9">
								<div class="d-flex align-center">
									<PhoneTemplate
										:disabled="pageLoading"
										id="did"
										placeholder="DID"
										class-name="mt-0"
										v-model="contact.did_number"
										:loading="pageLoading"
										@loading="($event) => (pageLoading = $event)"
										@blur="validatePhone(cindex)"
										@validity="phoneValid($event, cindex, 'did')"
										@clear="contact.did_number = null"
									></PhoneTemplate>
									<v-icon class="mb-3" color="red" @click="removeContactField('did')"
										>mdi-minus-circle-outline</v-icon
									>
								</div>
							</v-col>

							<!-- Fax Field -->
							<v-col v-if="showFax || contact.fax != null" md="3" class="mt-2 text-end">
								<label class="fw-500">Contact Fax</label>
							</v-col>
							<v-col v-if="showFax || contact.fax != null" md="9">
								<div class="d-flex align-center">
									<PhoneTemplate
										:disabled="pageLoading"
										id="fax"
										class-name="mt-0"
										placeholder="Fax"
										v-model="contact.fax"
										:loading="pageLoading"
										@loading="($event) => (pageLoading = $event)"
										@blur="validatePhone(cindex)"
										@validity="phoneValid($event, cindex, 'fax')"
										@clear="contact.fax = null"
									></PhoneTemplate>
									<v-icon class="mb-3" color="red" @click="removeContactField('fax')"
										>mdi-minus-circle-outline</v-icon
									>
								</div>
							</v-col>

							<v-col md="3" class="mt-2 text-end">
								<label class="fw-500">Contact Email</label>
							</v-col>
							<v-col md="9" class="mb-3">
								<v-text-field
									hide-details
									outlined
									placeholder="Email"
									v-model="contact.email_address"
									:rules="[
										vrules.validEmail(contact.email_address, 'contact Email'),
										vrules.maxLength(contact.email_address, 'contact Email', 30),
									]"
									:disabled="pageLoading"
									:loading="pageLoading"
								>
								</v-text-field>
								<!-- <TextInput
									dense
									id="mobile"
									hide-details
									type="email"
									placeholder="Email"
									:disabled="pageLoading"
									:loading="pageLoading"
									class="mt-0"
									v-model="contact.email_address"
								></TextInput> -->
							</v-col>

							<v-col md="3" class="mt-2 text-end">
								<label class="fw-500">Designation</label>
							</v-col>
							<v-col md="9" class="mb-3">
								<div class="d-flex align-center">
									<AutoCompleteInput
										class="pe-8"
										dense
										id="designation"
										hide-details
										placeholder="Designation"
										:items="designation_type_items"
										:disabled="pageLoading"
										:loading="pageLoading"
										item-text="text"
										v-model="contact.designation"
										item-value="text"
										v-on:change="get_reminder_values()"
									>
									</AutoCompleteInput>
									<ManageDescriptionDialog
										title="Designation"
										customClass="mt-2 px-0"
										typeId="25"
										v-on:update="getSettings()"
										v-on:create_setting="designation"
										v-on:close="getSettings()"
									>
									</ManageDescriptionDialog>
								</div>
							</v-col>
							<v-col md="3" class="mt-2 text-end">
								<label class="fw-500">Department</label>
							</v-col>
							<v-col md="9" class="mb-3">
								<div class="d-flex align-center">
									<AutoCompleteInput
										dense
										class="pe-8"
										id="department"
										hide-details
										placeholder="Department"
										:items="department_type_items"
										:disabled="pageLoading"
										:loading="pageLoading"
										item-text="text"
										v-model="contact.department"
										item-value="value"
										v-on:change="get_reminder_values()"
									>
									</AutoCompleteInput>
									<ManageDescriptionDialog
										title="Department"
										customClass="mt-2 px-0"
										typeId="60"
										v-on:update="getSettings()"
										v-on:close="getSettings()"
										v-on:create_setting="department"
									>
									</ManageDescriptionDialog>
								</div>
							</v-col>

							<v-col md="3" class="mt-1 text-end">
								<label class="fw-500">Tags</label>
							</v-col>
							<v-col md="9" class="mb-1">
								<TagsAutoSearch type="contact" v-model="contact.tags" />
							</v-col>

							<v-col md="3" class="mt-3 text-end">
								<label class="fw-500">Company</label>
							</v-col>
							<v-col md="9" class="mb-0">
								<div class="d-flex align-center">
									<AutoCompleteInput
										:items="companylist"
										item-value="id"
										item-text="company_name"
										dense
										class="pe-8"
										id="department"
										hide-details
										placeholder="company"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-model="contact.company"
										@keyup="searchCompany"
									>
									</AutoCompleteInput>
									<v-icon @click="contactAddDialog" class="cursor-pointer" color="blue"
										>mdi-domain-plus</v-icon
									>
								</div>
							</v-col>
							<v-col md="3" class="mt-2 text-end">
								<label class="fw-500">Description</label>
							</v-col>
							<v-col md="9" class="">
								<TextAreaInput
									hide-details
									placeholder="Description"
									v-model="contact.description"
									:word-limit="200"
									:rows="5"
								></TextAreaInput>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</div>
			<template v-slot:append>
				<div class="py-3 px-5 d-flex justify-content-end border-top">
					<v-btn depressed tile class="mr-2 cancel_btn" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						class="blue darken-4 save_btn"
						:loading="pageLoading"
						:disabled="pageLoading || !formValid"
						v-on:click="update_or_create()"
						>Save</v-btn
					>
				</div>
			</template>
		</v-navigation-drawer>
		<CompanyAddDialog
			v-if="companyDialog"
			v-on:close="companyDialog = false"
			:company-dialog="companyDialog"
			v-on:success="
				companyDialog = false;
				getCompany();
			"
		>
		</CompanyAddDialog>
	</div>
</template>

<script>
import { POST, GET, PUT } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";

// import TextInput from "@/view/components/TextInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import MainMixin from "@/core/mixins/main.mixin.js";
import TagsAutoSearch from "@/view/components/TagsAutoSearch";
import CompanyAddDialog from "@/view/components/CompanyAddDialog";
export default {
	mixins: [MainMixin],
	name: "Address",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		titleStatus: {
			type: String,
		},
		id: {
			type: Number,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			designation_type_items: [],
			customerData: [],
			companylist: [],
			department_type_items: [],
			showAdditionalFields: false,
			companyDialog: false,
			showDID: false,
			typeId: 25,
			typeId1: 60,
			showFax: false,
			nextField: "DID",
			salutation_type_items: ["Mr", "Mrs"],
			contact: {
				id: 0,
				salutation: "Mr",
				contact_name: null,
				mobile: null,
				landline: null,
				did: null,
				fax: null,
				email: null,
				company: null,
				designation: null,
				department: null,
				description: null,
				tags: [],
			},
		};
	},
	components: {
		CompanyAddDialog,
		// TextInput,
		AutoCompleteInput,
		TagsAutoSearch,
		TextAreaInput: () => import("@/view/components/TextAreaInput"),
		PhoneTemplate: () => import("@/view/components/Phone"),
		ManageDescriptionDialog: () => import("@/view/pages/leads/components/ManageDescriptionDialog"),
		//SearchAddress: () => import("@/view/components/Search-Address"),
	},
	mounted() {
		if (this.id > 0) {
			this.findContact();
		}
		this.getCompany();
		// this.getCustomerData();

		// this.getdesignation();
	},
	methods: {
		searchCompany() {
			const inputValue = event.target.value;
			// console.log('Input value:', inputValue);
			if (inputValue && inputValue.length >= 3) {
				this.$store
					.dispatch(GET, {
						url: `find-company/${inputValue}`,
					})
					.then((response) => {
						this.companylist = response; // Assuming data is in response.data

						console.log(response);
					})
					.catch((error) => {
						console.error("Error fetching contact:", error);
					});
			}
		},
		findContact() {
			this.id = this.id;

			this.$store
				.dispatch(GET, {
					url: `find-single-contact/${this.id}`,
				})
				.then((response) => {
					this.contact = response?.data; // Assuming data is in response.data
					this.contact.tags = response?.data?.tag;
					this.contact.company = response?.data?.company?.id;

					console.log(response);
				})
				.catch((error) => {
					console.error("Error fetching contact:", error);
				});
		},
		getCompany() {
			this.$store
				.dispatch(GET, {
					url: `get-company`,
				})
				.then((data) => {
					this.companylist = data;
					console.log(data);
				})

				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		// getCustomerData() {
		// 	this.$store
		// 		.dispatch(GET, {
		// 			url: `customer-list`,
		// 		})
		// 		.then((data) => {
		// 			if (data && data.length) {
		// 				this.customerData = data;
		// 			} else {
		// 				this.customerData = [];
		// 			}
		// 		})
		// 		.catch((error) => {
		// 			console.log({
		// 				error,
		// 			});
		// 		})
		// 		.finally(() => {
		// 			this.pageLoading = false;
		// 		});
		// },
		contactAddDialog() {
			this.companyDialog = true;
		},
		department(param) {
			this.department_type_items = param;
		},
		designation(param) {
			this.designation_type_items = param;
		},

		// getdepartment() {
		// 	const _this = this;
		// 	_this.$store
		// 		.dispatch(GET, {
		// 			url: `single-setting/${this.typeId1}`,
		// 		})
		// 		.then((data) => {
		// 			if (data && data.length) {
		// 				this.department_type_items = data;
		// 			} else {
		// 				this.department_type_items = [
		// 					{
		// 						text: null,
		// 					},
		// 				];
		// 			}
		// 		})
		// 		.catch((error) => {
		// 			console.log({ error });
		// 		});
		// },
		saveData() {
			this.pageLoading = true;
			console.log(this.contact, "contact");
			this.$store
				.dispatch(POST, { url: `create-contact`, data: this.contact })
				.then(({ data }) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Contact added successfully." },
					]);
					this.$refs.form.reset();
					this.$emit("createSuccess", data);

					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateData() {
			this.pageLoading = true;

			this.$store
				.dispatch(PUT, {
					url: `create-contact/${this.id}`,
					data: this.contact,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Contact updated successfully." },
					]);
					this.$emit("createSuccess", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["form"]);

			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}
			if (this.id) {
				this.updateData();
			} else {
				this.saveData();
			}
		},
		addContactField() {
			if (this.nextField === "DID") {
				this.showDID = true;
				this.nextField = "Fax";
			} else if (this.nextField === "Fax") {
				this.showFax = true;
				this.nextField = ""; // No more fields to show
			}
		},
		removeContactField(field) {
			if (field === "did") {
				this.contact.did = null;
				this.showDID = false;
				this.nextField = "DID";
			} else if (field === "fax") {
				this.contact.fax = null;
				this.showFax = false;
				this.nextField = "Fax";
			}
		},
		setAddress(e) {
			console.log({ e });

			this.customer.postal_code = e.postal_code;
			this.customer.address_line_1 = e.address1 && e.address1 != "NIL" ? e.address1 : "";
			this.customer.address_line_2 = e.address2 && e.address2 != "NIL" ? e.address2 : "";
			this.customer.unit_number = e.unit_number;
		},
	},
};
</script>
<style>
aside.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--open.v-navigation-drawer--right.v-navigation-drawer--temporary.theme--light {
	z-index: 99 !important;
}
</style>
