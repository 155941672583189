var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position-relative"},[_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.vMask),expression:"vMask"}],ref:_vm.inputRef,staticClass:"pt-0",class:{ ..._vm.customClass, 'mt-2': !_vm.hideTopMargin },attrs:{"id":_vm.id,"label":_vm.label,"loading":_vm.loading || _vm.pageLoading,"type":_vm.type,"disabled":_vm.disabled || _vm.pageLoading,"clearable":_vm.clearable,"readonly":_vm.readonly,"prefix":_vm.prefix,"suffix":_vm.suffix,"hide-details":_vm.hideDetails,"hide-spin-buttons":_vm.hideSpinButtons,"dense":"","filled":"","solo":"","flat":"","rules":[..._vm.rules, _vm.fieldNameError ? false : true],"autofocus":_vm.autofocus,"append-outer-icon":_vm.appendOuterIcon,"append-icon":_vm.appendIcon,"prepend-inner-icon":_vm.prependInnerIcon,"placeholder":_vm.placeholder,"reverse":_vm.reverse},on:{"click:clear":function($event){return _vm.$emit('click:clear', true)},"click:append-outer":function($event){return _vm.$emit('click:append-outer', true)},"keyup":(e) => {
				_vm.getExistingData(_vm.textinput);
				_vm.$emit('keyup', e);
			},"keypress":(e) => {
				_vm.getExistingData(_vm.textinput);
				_vm.$emit('keypress', e);
			},"paste":(e) => _vm.onPaste(e),"click":function($event){return _vm.$emit('click', true)},"blur":function($event){_vm.isFocused = false;
			_vm.$emit('blur', _vm.textinput);},"focus":function($event){_vm.isFocused = true}},model:{value:(_vm.textinput),callback:function ($$v) {_vm.textinput=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"textinput"}},'v-text-field',_vm.vBindAttrs,false),_vm.vOn)),(_vm.isFocused)?[(_vm.suggestedItems && _vm.suggestedItems.length)?_c('div',{staticClass:"suggested-content elevation-2"},[_vm._l((_vm.suggestedItems),function(item,index){return [_c('v-list-item',{key:index,staticClass:"py-2"},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1),_c('span',{staticClass:"red--text px-3 pt-1 text_small"},[_vm._v("Already exists ")])],1),_c('v-divider',{key:`index--${index}`,staticClass:"my-0"})]})],2):_vm._e()]:_vm._e(),(_vm.fieldNameError)?_c('span',{staticClass:"red--text text--darken-1 font-small",domProps:{"innerHTML":_vm._s(_vm.fieldNameError)}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }