<template>
	<div class="">
		<div class="v-data-table bt-table theme--light">
			<template v-if="pageLoading">
				<div class="mx-20 my-40 text-center">
					<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
				</div>
			</template>
			<div v-else class="">
				<div class="v-data-table__wrapper table_height" ref="btt_height">
					<table class="w-100">
						<thead>
							<tr>
								<th
									v-for="(th, th_idx) in allowedSortedThead"
									:key="th.key + '_' + th_idx"
									:width="th.width"
								>
									<v-layout>
										<v-flex class="text-left">
											<template v-if="th.checkbox">
												<v-checkbox
													dense
													v-model="checkAll"
													:ripple="false"
													hide-details
													color="blue darken-4"
													class="m-0 p-0"
												></v-checkbox>
											</template>
											<template v-else>
												{{ th.name }}
											</template>
										</v-flex>
										<v-flex v-if="th.key == 'reminder' && th.type == 'opportunity'" class="text-right ml-2">
											<template v-if="th.sort == 'ASC'">
												<v-icon small color="blue" @click="sortTableAccColumn('DESC', th)" link
													>mdi-sort-ascending</v-icon
												>
											</template>
											<template v-if="th.sort == 'DESC'">
												<v-icon small color="blue" @click="sortTableAccColumn('ASC', th)" link>
													mdi-sort-descending
												</v-icon>
											</template>
										</v-flex>
									</v-layout>
								</th>
							</tr>
						</thead>
						<tbody>
							<template v-if="tbody?.length">
								<tr
									v-for="(td, td_idx) in tbody"
									:key="'domain_' + td.id + '_' + td_idx"
									:class="getAciveNotes(td.id)"
								>
									<template v-for="(th, th_idx) in allowedSortedThead">
										<td
											:key="'domain_data_$_' + th.key + '_' + th_idx"
											:width="th.width"
											v-on:click="routeToDetail(th.order, td.id, th.type, td, th.key)"
										>
											<template v-if="th.checkbox">
												<v-checkbox
													dense
													:ripple="false"
													:value="td.id"
													hide-details
													v-model="selectedRows"
													multiple
													@change="selectRow"
													class="m-0 p-0"
													color="blue darken-4"
												></v-checkbox>
											</template>
											<template v-else-if="th.key == 'stage'">
												<div v-on:click.stop class="d-flex justify-space-between align-center mb-1">
													<v-menu offset-y min-width="100px" style="padding: 0px 4px" rounded bottom>
														<template v-slot:activator="{ on, attrs }">
															<div
																v-bind="attrs"
																v-on="on"
																class="d-flex justify-space-between align-center"
																:style="{
																	border: `1px solid ${leadStatusColor(leadStatus(td.status))?.border}`,
																	background: `${leadStatusColor(leadStatus(td.status))?.border}`,
																}"
																style="padding: 0px 6px; width: 120px; border-radius: 3px"
															>
																<span class="text-capitalize white--text font-level-1">
																	{{ leadStatus(td.status) }}
																</span>
																<v-icon
																	class="text-white"
																	style="font-size: 22px"
																	:color="taskStatusColor(td, 'status')"
																	small
																>
																	mdi-menu-down
																</v-icon>
															</div>
														</template>

														<v-list elevation>
															<v-list-item
																v-on:change="updateOpportunity(item.value, td.id, 'status', td_idx, item?.text)"
																class="list-item cursor-pointer p-1"
																v-for="(item, index) in statusList"
																:key="index"
															>
																<v-list-item-title class="pl-4">
																	<div class="d-flex">
																		<div
																			:style="{
																				backgroundColor: leadStatusColor(item?.text)?.border,
																				height: '15px',
																				width: '15px',
																				borderRadius: '50%',
																			}"
																		></div>
																		&nbsp;
																		<div>
																			{{ item?.text }}
																		</div>
																	</div>
																</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-menu>
												</div>
											</template>
											<template v-else-if="th.key == 'pin'">
												<div class="d-flex align-center cursor-pointer">
													<div class="">
														<v-tooltip top class="pa-1 rotate--20deg" content-class="custom-top-tooltip">
															<template #activator="{ on, attrs }">
																<v-icon
																	v-on="on"
																	v-bind="attrs"
																	color="red"
																	class="rotate--20deg mr-3"
																	v-model="td.pinned"
																	@click.prevent.stop="leadPinned(td.id, td.status, 'pinned', !td.pinned)"
																	>{{ td.pinned ? "mdi-circle" : "mdi-circle-outline" }}
																</v-icon>
															</template>
															<span>{{ td.pinned ? "Unpin" : "Pin" }}</span>
														</v-tooltip>
													</div>
													<div class="">
														<v-tooltip top class="pa-1 rotate--20deg" content-class="custom-top-tooltip">
															<template #activator="{ on, attrs }">
																<v-icon
																	v-on="on"
																	v-bind="attrs"
																	color="blue"
																	class="rotate--20deg"
																	v-model="td.pin"
																	@click.prevent.stop="leadPinned(td.id, td.status, 'pin', !td.pin)"
																	>{{ td.pin ? "mdi-circle" : "mdi-circle-outline" }}
																</v-icon>
															</template>
															<span>{{ td.pin ? "Unpin" : "Pin" }}</span>
														</v-tooltip>
													</div>
												</div>
											</template>
											<template v-else-if="th.key == 'lead'">
												<div class="">
													<Chip :text="td.barcode" color="cyan"></Chip>
												</div>
											</template>
											<template v-else-if="th.key == 'priority'">
												<div v-on:click.stop class="d-flex justify-space-between align-center mb-1">
													<v-menu offset-y min-width="100px" style="padding: 0px 4px" rounded bottom>
														<template #activator="{ on, attrs }">
															<div
																v-bind="attrs"
																v-on="on"
																class="d-flex justify-space-between align-center"
																:style="{ border: `1px solid ${leadStatusColor(td.opportunity)?.border}` }"
																style="
																	padding: 0px 6px;
																	background-color: #f7faff;
																	width: 130px;
																	border-radius: 9px;
																"
															>
																<span
																	class="text-capitalize font-level-1"
																	:class="leadStatusColor(td.opportunity)?.text + '--text'"
																	>{{ td.opportunity ? td.opportunity.replace("-", " ") : "No Opportunity" }}</span
																>
																<v-icon style="font-size: 22px" :color="taskStatusColor(td, 'opportunity')" small
																	>mdi-menu-down</v-icon
																>
															</div>
														</template>

														<v-list elevation>
															<v-list-item
																v-on:change="
																	updateOpportunity(item.value, td.id, 'opportunity', td_idx, item?.text)
																"
																class="list-item cursor-pointer p-1"
																v-for="(item, index) in opportunityList"
																:key="index"
															>
																<v-list-item-title class="pl-4">
																	<div class="d-flex">
																		<div
																			:style="{
																				backgroundColor: leadStatusColor(item.value)?.border,
																				height: '15px',
																				width: '15px',
																				borderRadius: '50%',
																			}"
																		></div>
																		&nbsp;
																		<div>{{ item?.text }}</div>
																	</div>
																</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-menu>
												</div>
											</template>
											<template v-else-if="th.key == 'action'">
												<v-menu offset-y left max-width="100">
													<template v-slot:activator="{ on, attrs }">
														<v-btn depressed fab x-small color="default" v-bind="attrs" v-on="on">
															<v-icon>mdi-dots-vertical</v-icon>
														</v-btn>
													</template>
													<v-list dense>
														<v-list-item
															v-for="(item, index) in actionList"
															:key="index"
															@click="openAction(item, td.id)"
														>
															<v-list-item-title class="cursor-pointer"
																><v-icon small class="me-2">{{ item.icon }}</v-icon
																>{{ item.title }}</v-list-item-title
															>
														</v-list-item>
													</v-list>
												</v-menu>
												<!-- <div class="d-flex justify-space-around align-center">
												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-icon
															@click="openAction('edit', td.id)"
															v-on="on"
															v-bind="attrs"
															class="cursor-pointer"
															color="blue"
															>mdi-pencil
														</v-icon>
													</template>
													<span>Edit Company</span>
												</v-tooltip>

												

												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-icon
															v-on="on"
															@click.prevent.stop="openAction('delete', td.id)"
															v-bind="attrs"
															class="cursor-pointer"
															color="red"
														>
															mdi-delete
														</v-icon>
													</template>
													<span>Delete Company</span>
												</v-tooltip>
											</div> -->
											</template>

											<template v-else-if="th.key == 'opportunity'">
												<v-row align="center">
													<v-col class="d-flex align-center" cols="auto">
														<v-avatar class="me-2" color="primary" size="30">
															<span class="text-white">{{ getFirstTwoLetters(td?.name) }}</span>
														</v-avatar>
														<ShowValue :object="td" object-key="name" label=" Name"></ShowValue>
													</v-col>
												</v-row>
											</template>

											<template v-else-if="th.key == 'source'">
												<template v-if="td?.source">
													<div v-on:click.stop class="d-flex justify-space-between align-center mb-1">
														<v-menu
															offset-y
															min-width="100px"
															style="padding: 0px 4px"
															rounded
															bottom
															content-class="rounded-lg"
															nudge-left="144"
														>
															<template v-slot:activator="{ on, attrs }">
																<div
																	v-bind="attrs"
																	v-on="on"
																	class="d-flex justify-space-between align-center"
																	:style="taskStatusBorder(td, 'source')"
																	style="
																		padding: 0px 6px;
																		background-color: #f7faff;
																		width: 130px;
																		border-radius: 9px;
																	"
																>
																	<span
																		class="text-capitalize font-level-1"
																		:class="taskStatusColor(td, 'source')"
																		>{{ td?.source?.replace("-", " ") }}</span
																	>
																	<v-icon color="blue" style="font-size: 22px" small>
																		{{ attrs && attrs["aria-expanded"] == "true" ? "mdi-menu-up" : "mdi-menu-down" }}
																	</v-icon>
																</div>
															</template>

															<v-list elevation>
																<v-list-item
																	v-on:change="updateOpportunity(item.text, td.id, 'source', td_idx, item?.text)"
																	class="list-item cursor-pointer p-1"
																	v-for="(item, index) in sourceList"
																	:key="index"
																>
																	<div class="py-1 px-3 text-uppercase fw-600">{{ item?.text }}</div>
																</v-list-item>
															</v-list>
														</v-menu>
													</div>
												</template>
												<em v-else class="text-muted">no Source</em>
											</template>
											<template v-else-if="th.key == 'company'">
												<template v-if="td?.company?.company_name">
													<div class="">
														<!-- {{td.company}} -->
														<div style="text-transform: uppercase" v-if="td?.company?.company_name">
															<v-icon small class="me-1">mdi-domain</v-icon>{{ td?.company?.company_name }}
														</div>
														<div class="" v-if="td?.website">
															<v-icon small class="me-1">mdi-web</v-icon
															><span
																class="cursor-pointer"
																v-on:click="copyNumber(td?.website, 'Company Website')"
																>{{ td?.website }}</span
															>
														</div>
													</div>
												</template>
												<em v-else class="text-muted">no Company</em>
											</template>
											<template v-else-if="th.key == 'pic_details'">
												<template v-if="td?.company">
													<div class="">
														<div class="" v-if="td.company_email">
															<v-icon small class="me-1">mdi-email</v-icon
															><span
																class="cursor-pointer"
																v-on:click="copyNumber(td.company_email, 'Company Email')"
																>{{ td.company_email }}</span
															>
														</div>
														<div class="" v-if="td?.company_phone">
															<v-icon small class="me-1">mdi-phone</v-icon
															><span
																class="cursor-pointer"
																v-on:click.stop="copyNumber(td?.company_phone, 'phone')"
																>{{ td?.company_phone }}</span
															>
														</div>
													</div>
												</template>
												<em v-else class="text-muted">no Company</em>
											</template>
											<template v-else-if="th.key == 'contact'">
												<template v-if="td?.contact?.contact_name">
													<div class="" style="text-transform: uppercase" v-if="td?.contact?.contact_name">
														<v-icon small class="me-1">mdi-account</v-icon>{{ td?.contact?.contact_name }}
													</div>
												</template>
												<em v-else class="text-muted">no Contact</em>
											</template>
											<template v-else-if="th.key == 'contact_details'">
												<div class="" v-if="td.contact_email">
													<v-icon small class="me-1">mdi-email</v-icon
													><span
														class="cursor-pointer"
														v-on:click="copyNumber(td.contact_email, 'Contact Email')"
														>{{ td.contact_email }}</span
													>
												</div>
												<!-- <em v-else class="text-muted">no email</em> -->
												<div class="" v-if="td.contact_phone">
													<v-icon small class="me-1">mdi-cellphone</v-icon
													><span
														class="cursor-pointer"
														v-on:click.stop="copyNumber(td.contact_phone, 'mobile')"
														>{{ td.contact_phone }}</span
													>
												</div>
												<!-- <em v-else class="text-muted">no number</em> -->
											</template>

											<template v-else-if="th.key == 'note'">
												<div class="d-flex justify-space-between" v-on:click="openRemark(td)">
													<span
														v-if="td?.notes && td?.notes[0] && td?.notes[0]?.notes_remark"
														class="custom-line-dot"
														v-html="td?.notes[0]?.notes_remark"
													></span>
													<span v-else-if="td?.notes[0]?.attachment_name">
														{{ td?.notes[0]?.attachment_name }}
													</span>
													<span v-else class="custom-line-dot"
														><em v-if="!td?.notes[0]?.notes_remark" class="text-muted">no notes</em></span
													>
													<v-icon
														style="font-size: 18px; margin-top: -12px"
														:color="
															!td?.notes[0]?.notes_remark && !td?.notes[0]?.attachment_name ? '#c5bfbf' : 'green'
														"
														small
														v-if="td"
														@click.stop.prevent="openRemark(td, 'lead', th.key)"
														>mdi-chat
													</v-icon>
												</div>
											</template>

											<template v-else-if="th.key == 'address'">
												<template v-if="td?.company?.address_line_1 || td?.company?.address_line_2">
													<div class="address">
														<v-icon class="me-1" small>mdi-map</v-icon>{{ td?.company?.address_line_1 }}
														{{ td?.company?.address_line_2 }}
													</div>
												</template>
												<template v-else> - </template>
											</template>
											<template v-else-if="th.key == 'amount'"> ${{ td.amount }} </template>

											<!-- <template v-else-if="th.key == 'reminder' ">
												<div class="cursor-pointer mb-1">
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-calendar</v-icon>
														</b></label
													>
													<span
														:class="getColorClass(td?.reminder?.start_date)"
														style="font-weight: 500"
														v-if="
															_getValue(td, 'reminder.start_date') && _getValue(td, 'totalreminder.length') > 0
														"
														>{{ formatDate(td?.reminder?.start_date) }}
													</span>

													<em v-else class="text-muted">no reminder date</em>
												</div>
												<template v-if="td?.totalreminder?.length > 1">
													<v-chip
														small
														color="blue white--text"
														label
														class="ml-2"
														v-on:click.prevent.stop="listReminder(td)"
													>
														<span class=""> +{{ td?.totalreminder?.length - 1 }} More</span>
													</v-chip>
												</template>
												
											</template> -->
											<template v-else-if="th.key == 'reminder'">
												<div class="cursor-pointer">
													<span
														v-if="
															_getValue(td, 'reminder.start_date') && _getValue(td, 'upcomingReminderCount') > 0
														"
													>
														<v-icon size="18">mdi-calendar</v-icon>
														<span :class="getColorClass(td.reminder.start_date)" style="font-weight: 500"
															>{{ formatDate(td.reminder.start_date) }}
														</span>
														<UpdateReminderMenu
															:edit-reminder.sync="editReminder"
															:id="td.reminder.id"
															v-on:close="editReminder = false"
															v-on:success="reminderSuccess"
														/>
													</span>
													<em v-else class="text-muted">no reminder date</em>
												</div>
												<!-- upcomingReminderCount | reminderCount -->
												<template v-if="td.upcomingReminderCount > 1">
													<v-chip
														x-small
														color="blue white--text"
														label
														class="ml-2"
														v-on:click.prevent.stop="listReminder(td)"
													>
														<span class=""> +{{ td.upcomingReminderCount - 1 }} More</span>
													</v-chip>
												</template>
												<!-- <div>
													<label class="font-size-14 mb-1 mr-1"
														><b>
															<v-icon size="18">mdi-calendar</v-icon>
														</b></label
													>
													<span v-if="td.reminder && td.reminder.end_date">{{
														formatDate(td.reminder.end_date)
													}}</span>
													<em v-else class="text-muted">no end date</em>
												</div> -->
											</template>

											<template v-else-if="th.key == 'added_at'">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<template v-if="types == 1">
															<v-chip v-bind="attrs" v-on="on" label outlined color="green" text-color="green">{{
																formatTime2(td.added_at)
															}}</v-chip>
														</template>
														<template v-else>
															<v-chip v-bind="attrs" v-on="on" label outlined color="green" text-color="green">{{
																td.added_at
															}}</v-chip></template
														>
													</template>
													<span>Created date</span>
												</v-tooltip>
											</template>

											<template v-else-if="th.key == 'updated_at'">
												<div v-if="td.updated_at != 0">
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-chip v-bind="attrs" v-on="on" label outlined color="green" text-color="green">{{
																formatTime2(td.updated_at)
															}}</v-chip></template
														>
														<span>Updated At</span>
													</v-tooltip>
												</div>
												<div v-else>
													<em class="text-muted">no updated</em>
												</div>
											</template>
											<template v-else-if="th.key == 'added_by'">
												<v-tooltip bottom>
													<template v-slot:activator="{ on, attrs }">
														<v-chip v-bind="attrs" v-on="on" pill outlined>
															<v-avatar left>
																<v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg" alt="John"></v-img>
															</v-avatar>
															<span class="text-capitalize">{{ td?.added_by?.display_name }}</span>
														</v-chip>
													</template>
													<span>Created by</span>
												</v-tooltip>
											</template>

											<template v-else-if="th.key == 'product'">
												<div class="d-flex align-items-center" v-if="td.leadproduct?.length > 0">
													<div
														v-for="(num, index) in td.leadproduct.slice(0, 2)"
														:key="index"
														class="d-flex align-items-center me-2 mb-1"
													>
														<v-chip size="small" variant="outlined" :color="num?.product?.status_color">
															{{ num?.product?.text }}
														</v-chip>
													</div>
													<template v-if="td.leadproduct?.length > 2">
														<span class="ms-1 text-blue">+{{ td.leadproduct?.length - 2 }} more..</span>
													</template>
												</div>
												<template v-else>
													<ShowValue :object="td" object-key="product" label="product"></ShowValue>
												</template>
											</template>
											<template v-else-if="th.key == 'status'">
												<div @click.stop class="d-flex justify-space-between align-center"></div>
											</template>

											<template v-else-if="th.key == 'last_update'">
												<Chip
													class="mr-1"
													outlined
													:text="td.updated_at"
													textColor="green"
													color="green"
												></Chip>
											</template>
										</td>
									</template>
								</tr>
							</template>
							<template v-else>
								<tr>
									<td :colspan="15">
										<p class="m-0 row-not-found text-center py-3">
											<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
											Uhh... There <span class="text-lowercase">are no opportunity at the moment.</span>
										</p>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</div>
			<v-row class="listing-footer py-2" v-if="types != 1">
				<v-col v-if="tbody.length > 0" class="my-auto h6 text-capitalize"
					><b> {{ showingString }} entries</b>
				</v-col>
				<v-col class="my-auto" v-if="tbody.length > 0">
					<v-pagination v-model="currentPage" :length="totalPage" v-on:input="updatePagination($event)">
					</v-pagination
				></v-col>
			</v-row>
		</div>
		<CreateOpportunityDrawer
			v-if="update_opportunity"
			:id="id"
			:drawer="update_opportunity"
			title-status="Update"
			v-on:close="update_opportunity = false"
			v-on:createSuccess="getdata()"
		></CreateOpportunityDrawer>
		<FullDialog dense :dialog="remarkDialog">
			<template #title>
				{{ leadBarcode }} Notes
				<v-spacer></v-spacer>
				<v-btn
					depressed
					tile
					class="text-white"
					color="red darken-4"
					:disabled="pageLoading"
					icon
					@click="closeDialog('remark', 'lead')"
				>
					<v-icon>mdi-close-circle</v-icon>
				</v-btn>
			</template>
			<template #body>
				<AllNotesTab
					:relatedId="lead_id"
					:relatedType="'lead-notes'"
					:siggest-list="notesUserArr"
					create-url="lead-note"
					get-url="lead-note"
				>
				</AllNotesTab>
			</template>
			<!-- <template v-slot:action>
					<v-btn
						depressed
						tile
						class="text-white"
						color="red darken-4"
						:disabled="pageLoading"
						v-on:click="closeDialog('remark', 'lead')"
					>
						Close
					</v-btn>
				</template> -->
		</FullDialog>
	</div>
</template>
<script>
import Chip from "@/view/components/Chip";
import UpdateReminderMenu from "@/view/components/UpdateReminderMenu";
import ShowValue from "@/view/components/ShowValue";
import { SET_MESSAGE, SET_NOTE_FLAG } from "@/core/services/store/common.module";
import MomentJS from "moment-timezone";
import objectPath from "object-path";
import CreateOpportunityDrawer from "@/view/pages/leads/create/CreateOpportunityDrawer";
import { QUERY, PATCH } from "@/core/services/store/request.module";
import FullDialog from "@/view/components/FullDialog";
import AllNotesTab from "@/view/pages/leads/components/NotesTab3";
import { mapGetters } from "vuex";
import { SET_CURRENT_PAGE, SORT_TBODY } from "@/core/services/store/listing.module";
export default {
	name: "OpportunityListing",
	components: {
		ShowValue,
		Chip,
		CreateOpportunityDrawer,
		FullDialog,
		AllNotesTab,
		UpdateReminderMenu,
	},
	props: {
		tbody: {
			type: Array,
		},
		sourceList: {
			type: Array,
		},
		allowedSortedThead: {
			type: Array,
		},
		currentPage: {
			type: Number,
		},
		totalPage: {
			type: Number,
		},
		showingString: {
			type: String,
		},
		types: {
			type: Number,
		},
	},
	watch: {
		currentPage(param) {
			this.page = param;
		},
	},

	/* watch:{
		tbodys : {
			deep : true,
			indeterminate : true,
			handle(params){
                 this.tbody = params;
 			}
		}
	}, */
	data() {
		return {
			remarkDialog: false,
			pageLoading: null,
			update_opportunity: false,
			detailRoute: "opportunity-detail",
			leadBarcode: null,
			notesUserArr: [],
			lead_id: null,
			editReminder: false,
			// date: null,
			// sourceList: [
			// 	{ text: "All", type: 2, value: "" },
			// 	{ text: "Web Enquiry", value: "web-enquiry", type: 2 },
			// 	{ text: "Web Chat", value: "web-chat", type: 2 },
			// 	{ text: "Email Marketer", value: "email-marketer", type: 2 },
			// 	{ text: "Tele Call", value: "tele-call", type: 2 },
			// 	{ text: "Google Adwords", value: "google-adwords", type: 2 },
			// 	{ text: "FSM/AMS(Brochure Lead Form)", value: "fsm-ams", type: 2 },
			// 	{ text: "Facebook", value: "facebook", type: 2 },
			// 	{ text: "Referral", value: "referral", type: 2 },
			// 	{ text: "Intern", value: "intern", type: 2 },
			// 	{ text: "None", value: "none", type: 2 },
			// ],
			opportunityList: [
				{ text: "50 50", value: "50-50", type: 3 },
				{ text: "High Chance", value: "high-chance", type: 3 },
				{ text: "None", value: "none", type: 3 },
				{ text: "Low Chance", value: "low-chance", type: 3 },
			],

			statusList: [
				{
					id: 2,
					text: "Not Contacted",
					value: "not-contacted",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "purple",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 165,
				},
				{
					id: 3,
					text: "Follow UP",
					value: "follow-up",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "orange",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 118,
				},
				{
					id: 4,
					text: "Meeting",
					value: "meeting",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "pink",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 49,
				},
				{
					id: 5,
					text: "Quotation",
					value: "quotation",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "cyan",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 11,
				},
				{
					id: 6,
					text: "Rejected",
					value: "reject",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "red",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 148,
				},
				{
					id: 7,
					text: "Accepted",
					value: "accept",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "green",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 22,
				},
				{
					id: 8,
					text: "Negotiation",
					value: "negotiation",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "grey",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 22,
				},
			],
			// tbody: [

			// 	{
			// 		id: 2,
			// 		barcode: "BT-0000346",
			// 		pin: 1,
			// 		pinned: 0,
			// 		status: "Not Contacted",
			// 		opportunity: "High Chance",
			// 		source: "Google Adwords",
			// 		product_type: "SEO",
			// 		company_name: "abc Corp",
			// 		company_email: "abccorp@gmail.com",
			// 		company_website: "abcbthrust.com",
			// 		contact_name: "John doe",
			// 		contact_email: "info@gmail.com",
			// 		comtact_number: "+65-12345678",
			// 		notes: "",
			// 		reminder: [
			// 			{
			// 				start_date: "02/06/2024",
			// 				end_date: "02/06/2024",
			// 			},
			// 		],
			// 		unit_number: "72 Bendemeer Rd",
			// 		address_line_1: "#07-03 Luzerne S()",
			// 		address_line_2: "28 Lor 23 Geylang, 00074",
			// 		country: "Singapore",
			// 		postal_code: "11002",
			// 		amount: "123",
			// 		added_at: "1 Months Ago",
			// 	},
			// ],
			// tbodyNew : [],
			actionList: [
				{ title: "View ", value: "view_opportunity", icon: "mdi-eye" },
				{ title: "Edit ", value: "edit_opportunity", icon: "mdi-pencil" },
				// { title: "Delete", value: "delete_opportunity", icon: "mdi-delete" },
			],
			confirm_dialog: false,
			create_contact: false,
			type: null,
			checkbox: false,
			/* 	currentPage: 1, */
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			showingFrom: 0,
			showingTo: 0,
			editTask: {},
			attendieList: {},
		};
	},
	methods: {
		reminderSuccess() {
			this.$emit("createSuccess", true);
		},
		// saveDate() {
		// 	this.td.reminder.start_date = this.date;
		// 	this.editReminder = false;
		// },
		getAciveNotes(id) {
			if (id == this.notesActive) {
				return "bg-orange";
			}
			return "";
		},
		routeToDetail(order, id, type, updateData, key) {
			if (type == "notification") {
				return false;
			}
			if (order < 2 || order == 12) {
				return false;
			}
			if (key == "reminder" && type == "lead" && !updateData.reminder) {
				this.$emit("openReminder", updateData);
				return false;
			}
			if (key == "reminder" && type == "lead" && updateData.reminder) {
				this.$emit("openReminder", updateData);
				return false;
			}
			/* if (order == 7 && type == "lead" && updateData.reminder) {
				this.$emit("openReminderlist", id);
				return false;
			} */
			if (key == "remark" && (type == "lead" || type == "customer")) {
				this.openRemark(updateData, type);
				return false;
			}

			if (
				this.detailRoute == "opportunity-detail" ||
				this.detailRoute == "task-detail" ||
				this.detailRoute == "meeting-detail"
			) {
				let filterQuery = this.$route.query;
				this.$router.push({
					name: this.detailRoute,
					params: { id },
					query: { ...filterQuery, t: new Date().getTime(), viewType: false /* page  :this.page, */ },
				});
			} else if (this.isDialogDetail) {
				this.$emit("open-detail-dialog", id);
			} else {
				let filterQuery = this.$route.query;
				this.$router.push({
					name: this.detailRoute,
					params: { id },
					query: { ...filterQuery, t: new Date().getTime(), viewType: false /* page  :this.page, */ },
				});
			}
		},
		sortTableAccColumn(sortType, thead) {
			const { name, query, params } = this.$route;
			console.log(params, "params");
			console.log({ query });
			const SORT_DATA = { sortType, thead, endpoint: "opportunity", query: query };

			this.$router.push({ name, params, query: { ...query, reminder_sort: sortType } });
			this.$store
				.dispatch(SORT_TBODY, SORT_DATA)
				.catch((err) => {
					console.log({ err });
				})
				.finally(() => {
					this.$emit("toggleLoading", false);

					// this.$router.push({
					// 	name,
					// 	query: { ...query, reminder_sort: sortType },
					// 	params,
					// });
				});

			this.$emit("toggleLoading", false);
		},

		leadPinned(id, status, type, data) {
			this.$store
				.dispatch(PATCH, {
					url: `pin-lead/${id}`,
					data: {
						value: data,
						type: type,
						status: status,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Lead Pinned successfully." },
					]);

					this.$emit("reload:content", true);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		listReminder(data) {
			this.$emit("openReminderlist", data);
		},

		// openRemark(data, type) {
		// 	this.leadBarcode = data.barcode;
		// 	this.default_remakr = data.remark;
		// 	this.default_date = MomentJS(data.added_at);
		// 	this.lead_id = data.id;
		// 	if (type == "lead") {
		// 		this.remarkDialog = true;
		// 		if (data && data.user && this.notesUserArr.length == 0) {
		// 			this.notesUserArr.push({
		// 				id: data.user,
		// 				profile_img: data.user_profile_img ? data.user_profile_img : null,
		// 				barcode: data.user_barcode,
		// 				display_name: data.user_name ? data.user_name : null,
		// 			});
		// 		}
		// 	} else {
		// 		this.remarkCustomerDialog = true;
		// 		if (data && data.attendies && this.notesUserArr.length == 0) {
		// 			this.notesUserArr = data.attendies;
		// 		}
		// 	}
		// },
		getdata() {
			this.$emit("createSuccess", true);
		},
		getFirstTwoLetters(name) {
			if (!name) return "";
			return name.slice(0, 2).toUpperCase();
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `${type} <b>${data}</b> - successfully coppied.` },
			]);
		},
		openRemark(data) {
			this.leadBarcode = data.name;
			this.name = data.name;
			this.default_remakr = data.remark;
			this.default_date = toString(MomentJS(data.added_at));
			this.lead_id = data.id;
			this.$store.commit(SET_NOTE_FLAG, data.id);
			/* this.getRemark(data.id); */

			this.remarkDialog = true;
		},
		closeDialog(value) {
			if (value == "reminder") {
				this.reminderDialog = false;

				this.getLeadListing();
			} else {
				this.remarkDialog = false;
				this.$emit("createSuccess", true);
			}
		},
		openAction(action, id) {
			switch (action.value) {
				case "view_opportunity":
					this.$router.push({ name: "opportunity-detail", params: { id: id } });
					break;
				case "edit_opportunity":
					this.id = id;
					this.update_opportunity = true;
					this.$store.commit(SET_NOTE_FLAG, id);
					break;
				case "delete_opportunity":
					break;
			}
		},
		updatePagination(param) {
			// alert(param)
			// if (this.currentPage == param) {
			// 	return false;
			// }
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.$emit("reload:content", true);
			});
		},

		statusChange(status, id, type) {
			const _this = this;
			this.$store
				.dispatch(QUERY, {
					url: `change-opportunity-stage`,
					data: {
						status,
						id: id,
						type: type,
					},
				})
				.then((data) => {
					//   _this.reservation_status = data;
					_this.$store.commit(SET_MESSAGE, [{ model: true, message: "Updated Successfully." }]);
					this.$emit("reload:content", true);

					console.log(data, "_this.customer_email");

					/*  _this.purchaseOrder.supplier.barcode = data.supplier.barcode
 console.log(_this.purchaseOrder.supplier.barcode)     */
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {});
		},
		updateOpportunity(value, id, type, index, text) {
			if (type == "status") {
				this.statusChange(value, id, "status");

				this.tbody[index].status = value;
			} else if (type == "assign") {
				this.tbody[index].user_name = text;
			} else if (type == "opportunity") {
				this.tbody[index].opportunity = value;
				this.statusChange(value, id, "opportunity");
			} else if (type == "product_type") {
				this.tbody[index].product_type = value;
			} else if (type == "source") {
				this.statusChange(value, id, "source");

				this.tbody[index].source = value;
			}
		},
		leadStatus(status) {
			let found = this.statusList.find((v) => v.value == status);
			if (found) {
				return found?.text;
			} else {
				return null;
			}
		},
		getColorClass(start_date = "") {
			var startDate = MomentJS(start_date).format("YYYY-MM-DD HH:mm:ss");
			var now = MomentJS().format("YYYY-MM-DD HH:mm:ss");
			if (startDate < now) {
				return "red-color";
			} else if (startDate >= now) {
				return "blue-color";
			}
		},
		leadStatusColor(status) {
			switch (status) {
				case "Not Contacted":
					return { border: "purple", text: "purple" };
				case "Follow UP":
					return { border: "orange", text: "orange" };
				case "Meeting":
					return { border: "#e91e63", text: "pink" };
				case "Quotation":
					return { border: "#2196f3", text: "cyan" };
				case "Negotiation":
					return { border: "grey", text: "grey" };
				case "Rejected":
					return { border: "red", text: "red" };
				case "Accepted":
					return { border: "green", text: "green" };
				case "50-50":
					return { border: "#2196f3", text: "blue" };
				case "high-chance":
					return { border: "green", text: "green" };
				case "low-chance":
					return { border: "grey", text: "grey" };
				case "none":
					return { border: "grey", text: "grey" };
				case null:
					return { border: "grey", text: "grey" };
			}
		},
		_getValue(obj, key) {
			return objectPath.get(obj, key);
		},
	},
	computed: {
		...mapGetters(["notesActive"]),
		taskStatusColor: function () {
			return function name(row, type) {
				if (type == "source") {
					return "blue--text";
				}
				if (type == "product_type") {
					return "green--text";
				}
				if (type == "status") {
					return "orange--text";
				}
				if (type == "opportunity") {
					return row.opportunity == "high-chance"
						? "orange--text"
						: row.opportunity == "low-chance"
						? "cyan--text"
						: row.opportunity == "50-50"
						? "green--text"
						: "blue--text";
				}
			};
		},
		taskStatusBorder: function () {
			return function name(row, type) {
				if (type == "source") {
					return "border:1px solid #2196F3";
				}
				if (type == "product_type") {
					return "border:1px solid green";
				}
				if (type == "status") {
					return "border:1px solid orange";
				}
				if (type == "opportunity") {
					return row.opportunity == "high-chance"
						? "border:1px solid orange"
						: row.opportunity == "low-chance"
						? "border:1px solid cyan"
						: row.opportunity == "50-50"
						? "border:1px solid green"
						: "border:1px solid gray";
				}
			};
		},
	},
};
</script>

<style scoped>
.bt-table tbody tr:nth-child(even) {
	background-color: rgb(237, 242, 251);
}
.bt-table thead tr th:last-child {
	position: sticky;
	right: 0;
	z-index: 7;
}
.bt-table tbody tr td:last-child {
	position: sticky;
	right: 0;
	z-index: 7;
}
.text_climp {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}
.text_climp_notes {
	width: 150px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
.text_climpNotes {
	width: 500px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}
.address {
	text-overflow: ellipsis;
	width: 250px;
	overflow: hidden;
}
</style>
