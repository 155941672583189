<template>
	<div class="">
		<div v-if="drawer">
			<v-navigation-drawer
				v-model="drawer"
				fixed
				width="700"
				right
				temporary
				stateless
				style="z-index: 200"
			>
				<template v-slot:prepend>
					<div class="drawer-wrapper">
						<v-flex
							class="py-4 px-5 border-bottom drawer-heade d-flex justify-space-between"
							style="background-color: #f57c00"
						>
							<div class="font-level-3-bold text-white">{{ titleStatus }} Opportunity</div>
							<div class="bg-white" style="width: 200px; border-radius: 50px">
								<v-autocomplete
									style="border-radius: 50px !important"
									hide-details
									:items="statusList"
									placeholder="Select Status"
									outlined
									v-model="opportunity.status"
									item-text="text"
									item-value="value"
									class=""
								>
								</v-autocomplete>
							</div>
							<div class="bg-white custom_resize" style="width: 200px; border-radius: 50px">
								<v-autocomplete
									style="border-radius: 50px !important"
									:items="userList"
									v-model="opportunity.assign"
									item-text="first_name"
									item-value="id"
									depressed
									:readonly="currentUser.role != 1 && currentUser.role != 4"
									placeholder="Assigned To"
									hide-details
									outlined
									hide-selected
									class="cuatom-drop"
									color="blue darken-4"
									small-chips
									deletable-chips
								>
									<template #selection="data">
										<v-chip small v-bind="data.attrs" v-if="currentUser.role != 1 && currentUser.role != 4">
											<v-avatar left>
												<img v-if="data.item.profile_img" :src="data.item.profile_img" />
												<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
											</v-avatar>

											<span class="text-uppercase fw-500">{{ data.item.display_name }}</span>
										</v-chip>
										<v-chip
											small
											v-bind="data.attrs"
											v-else
											close
											@click="select"
											@click:close="removeOpp(item)"
										>
											<v-avatar left>
												<img v-if="data.item.profile_img" :src="data.item.profile_img" />
												<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
											</v-avatar>

											<span class="text-uppercase fw-500">{{ data.item.display_name }}</span>
										</v-chip>
									</template>
									<template #item="data">
										<div class="py-1 d-flex align-center justify-space-between w-100">
											<div class="d-flex align-center">
												<v-avatar size="38">
													<img v-if="data.item.profile_img" :src="data.item.profile_img" />
													<img v-else src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png" />
												</v-avatar>
												<div class="ms-2">
													<div class="fw-600 text-uppercase">{{ data.item.display_name }}</div>
													<div class="fw-600 text-muted">{{ data.item.email }}</div>
												</div>
											</div>
										</div>
									</template>
								</v-autocomplete>
							</div>
						</v-flex>
					</div>
				</template>
				<div class="drawer-wrapper">
					<v-form
						ref="form"
						id="form"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="update_or_create"
					>
						<div class="drawer-content pt-5 px-5">
							<v-row>
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Name </label>
								</v-col>
								<v-col md="9" class="mb-3">
									<v-text-field
										depressed
										hide-details
										outlined
										placeholder="Name"
										v-model="opportunity.name"
										:rules="[
											vrules.required(opportunity.name, 'Name'),
											vrules.maxLength(opportunity.name, 'Name', 30),
										]"
										:class="{
											required: !opportunity.name,
										}"
										:disabled="pageLoading"
										:loading="pageLoading"
									></v-text-field>
								</v-col>
								<v-col md="3" class="mt-2 d-none">
									<label class="fw-500">Opportunity</label>
								</v-col>
								<v-col md="9" class="mb-3 d-none">
									<v-radio-group row hide-details class="mt-0" v-model="opportunity.opportunity">
										<v-radio
											style="min-width: 100px !important"
											color="orange"
											label="High Chance"
											value="high-chance"
										></v-radio>
										<v-radio
											style="min-width: 100px !important"
											color="green"
											label="50 50"
											value="50-50"
										></v-radio>
										<v-radio
											style="min-width: 100px !important"
											color="cyan"
											label="Low Chance"
											value="low-chance"
										></v-radio>
										<v-radio
											style="min-width: 100px !important"
											color="blue"
											label="None"
											value="none"
										></v-radio>
									</v-radio-group>
								</v-col>

								<v-col md="3" class="mt-2 text-end d-none">
									<label class="fw-500">Status </label>
								</v-col>
								<v-col md="9" class="mb-3 d-none">
									<v-autocomplete
										hide-details
										:items="statusList"
										placeholder="Select Status"
										outlined
										v-model="opportunity.status"
										item-text="text"
										item-value="value"
										class="pr-8"
									>
									</v-autocomplete>
									<ManageDescriptionDialog
										title="Status"
										typeId="1"
										v-on:update="getSettings"
										customClass="mt-2 px-0"
									></ManageDescriptionDialog>
								</v-col>

								<v-col md="3" class="mt-2 text-end">
									<!-- {{opportunity}} -->
									<label class="fw-500">Product Type</label>
								</v-col>
								<v-col md="9" class="mb-3">
									<v-autocomplete
										hide-details
										:items="productList"
										placeholder="Product Type"
										outlined
										v-model="opportunity.product_type"
										:rules="[vrules.required(opportunity.product_type, 'Company Name')]"
										:class="{
											required: !opportunity.product_type,
										}"
										item-text="text"
										item-value="id"
										class=""
										multiple
									>
									</v-autocomplete>
									<!-- <ManageDescriptionDialog customClass="mt-2 px-0" title="Product Type" typeId="4" v-on:update="getSettings">
									</ManageDescriptionDialog> -->
								</v-col>

								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Company </label>
								</v-col>
								<v-col md="9" class="mb-3">
									<div class="d-flex align-center">
										<v-autocomplete
											:items="companylist"
											item-text="company_name"
											item-value="id"
											depressed
											placeholder="Select Company"
											:readonly="currentUser.userType != 'sales' && userType"
											hide-details
											outlined
											hide-selected
											@change="chnagecompany()"
											@keyup="searchCompany"
											class="cuatom-drop pr-1"
											color="blue darken-4"
											small-chips
											deletable-chips
											v-model="opportunity.company"
										>
											<template #selection="{ item, attrs, selected, select }">
												<v-chip
													v-bind="attrs"
													:input-value="selected"
													close
													@click="select"
													@click:close="removecompany(item)"
												>
													<v-avatar left color="primary" size="30"
														><span class="text-white">{{ item.company_name[0] }}</span></v-avatar
													>
													{{ getValue(item, "company_name") }}
												</v-chip>
											</template>
											<template #item="{ item }">
												<div class="d-flex align-center py-1">
													<v-avatar color="primary" size="30"
														><span class="text-white text-capitalize">{{ item.company_name[0] }}</span></v-avatar
													>
													<div class="ml-3 fw-500" :style="{ fontSize: '13px' }">
														<div class="text-uppercase">
															{{ item.company_name }}
														</div>
														<div class="text-muted">{{ item.phone }}</div>
													</div>
												</div>
											</template>
										</v-autocomplete>
										<v-icon @click="companyAddDialog" class="cursor-pointer" color="blue"
											>mdi-domain-plus</v-icon
										>
									</div>
									<div class="cust-req d-none">
										<TextValidateInput
											hide-details
											:disabled="pageLoading"
											:hideTopMargin="true"
											:loading="pageLoading"
											:rules="company_rules()"
											id="company-name"
											placeholder="Company Name"
											:validationField="{
												filter_type: 'company Name',
												field: 'company_name',
											}"
											:parent-id="opportunity.id"
											:current-id="opportunity.id"
											show-dropdown
											v-model="opportunity.company_name"
											v-on:keyup="appendDisplayName()"
											append-icon="mdi-domain"
										></TextValidateInput>
									</div>
								</v-col>
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Contact</label>
								</v-col>
								<v-col md="9" class="mb-3">
									<div class="d-flex align-center">
										<v-autocomplete
											:items="contactlist"
											item-text="name"
											item-value="id"
											depressed
											@change="changecontact()"
											placeholder="Select Contact"
											:readonly="currentUser.userType != 'sales' && userType"
											hide-details
											outlined
											hide-selected
											class="cuatom-drop pr-1"
											color="blue darken-4"
											small-chips
											deletable-chips
											v-model="opportunity.contact"
										>
											<template #selection="{ item, attrs, selected, select }">
												<v-chip
													small
													v-bind="attrs"
													:input-value="selected"
													close
													@click="select"
													@click:close="remove(item)"
												>
													<v-avatar left color="primary" size="30"
														><span class="text-white">{{ item.contact_name[0] }}</span></v-avatar
													>
													{{ getValue(item, "contact_name") }}
												</v-chip>
											</template>
											<template #item="{ item }">
												<div class="d-flex align-center py-1">
													<v-avatar color="primary" size="30"
														><span class="text-white">{{ item.contact_name[0] }}</span></v-avatar
													>
													<div class="ml-3 fw-500" :style="{ fontSize: '13px' }">
														<div class="text-uppercase">
															{{ item.contact_name }}
														</div>
														<div class="text-muted">{{ item.phone }}</div>
													</div>
												</div>
											</template>
										</v-autocomplete>
										<v-icon @click="contactAddDialog" class="cursor-pointer" color="blue"
											>mdi-account-plus</v-icon
										>
									</div>
								</v-col>
								<!-- Phone Field -->
								<v-col md="3" class="mt-2 text-end"> Company Phone </v-col>

								<v-col md="9">
									<div class="d-flex align-center">
										<PhoneTemplate
											placeholder="Company Number"
											:disabled="pageLoading"
											id="mobile_company"
											class-name="mt-0"
											v-model="opportunity.company_phone"
											:loading="pageLoading"
											@loading="($event) => (pageLoading = $event)"
											@blur="validatePhone(cindex)"
											@validity="phoneValid($event, cindex, 'Company phone')"
											@clear="opportunity.company_phone = null"
										></PhoneTemplate>
									</div>
								</v-col>
								<v-col md="3" class="mt-2 text-end"> Contact Mobile </v-col>

								<v-col md="9">
									<div class="d-flex align-center">
										<PhoneTemplate
											placeholder="Contact Number"
											:disabled="pageLoading"
											id="mobile_company"
											class-name="mt-0"
											v-model="opportunity.contact_phone"
											:loading="pageLoading"
											@loading="($event) => (pageLoading = $event)"
											@blur="validatePhone(cindex)"
											@validity="phoneValid($event, cindex, 'Company phone')"
											@clear="opportunity.contact_phone = null"
										></PhoneTemplate>
										<v-icon class="mb-3" color="blue" @click="addContactField"
											>mdi-plus-circle-outline</v-icon
										>
									</div>
								</v-col>

								<!-- DID Field -->
								<v-col v-if="showDID" md="3" class="mt-2 text-end">
									<label class="fw-500">Contact DID</label>
								</v-col>
								<v-col v-if="showDID" md="9">
									<div class="d-flex align-center">
										<PhoneTemplate
											placeholder="DID"
											:disabled="pageLoading"
											id="did"
											class-name="mt-0"
											v-model="opportunity.did"
											:loading="pageLoading"
											@loading="($event) => (pageLoading = $event)"
											@blur="validatePhone(cindex)"
											@validity="phoneValid($event, cindex, 'did')"
											@clear="opportunity.did = null"
										></PhoneTemplate>
										<v-icon class="mb-3" color="red" @click="removeContactField('did')"
											>mdi-minus-circle-outline</v-icon
										>
									</div>
								</v-col>

								<!-- Fax Field -->
								<v-col v-if="showFax" md="3" class="mt-2 text-end">
									<label class="fw-500">Fax</label>
								</v-col>
								<v-col v-if="showFax" md="9">
									<div class="d-flex align-center">
										<PhoneTemplate
											placeholder="Fax"
											:disabled="pageLoading"
											id="fax"
											class-name="mt-0"
											v-model="opportunity.fax"
											:loading="pageLoading"
											@loading="($event) => (pageLoading = $event)"
											@blur="validatePhone(cindex)"
											@validity="phoneValid($event, cindex, 'fax')"
											@clear="opportunity.fax = null"
										></PhoneTemplate>
										<v-icon class="mb-3" color="red" @click="removeContactField('fax')"
											>mdi-minus-circle-outline</v-icon
										>
									</div>
								</v-col>

								<!-- Email Field -->
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Company Email</label>
								</v-col>
								<v-col md="9" class="mb-3">
									<div class="d-flex">
										<v-text-field
											hide-details
											outlined
											placeholder="Company Email"
											v-model="opportunity.company_email"
											:rules="[
												vrules.validEmail(opportunity.company_email, 'Company Email'),
												vrules.maxLength(opportunity.company_email, 'Company Email', 30),
											]"
											:disabled="pageLoading"
											:loading="pageLoading"
										>
										</v-text-field>
										<!-- <TextInput
											dense
											id="email"
											hide-details
											type="email"
											placeholder="Company Email"
											:disabled="pageLoading"
											:loading="pageLoading"
											class="mt-0"
											v-model="opportunity.company_email"
										></TextInput> -->
									</div>
								</v-col>
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Contact Email</label>
								</v-col>
								<v-col md="9" class="mb-3">
									<div class="d-flex">
										<v-text-field
											hide-details
											outlined
											placeholder="Contact Email"
											v-model="opportunity.contact_email"
											:rules="[
												vrules.validEmail(opportunity.contact_email, 'Contact Email'),
												vrules.maxLength(opportunity.contact_email, 'Contact Email', 30),
											]"
											:disabled="pageLoading"
											:loading="pageLoading"
										>
										</v-text-field>
										<!-- <TextInput
											dense
											id="email"
											hide-details
											type="email"
											placeholder="Contact Email"
											:disabled="pageLoading"
											:loading="pageLoading"
											class="mt-0"
											v-model="opportunity.contact_email"
										></TextInput> -->
									</div>
								</v-col>
								<!-- Contact Email Field -->
								<v-col v-if="showContactEmail" md="3" class="mt-2 text-end">
									<label class="fw-500">Contact Email</label>
								</v-col>
								<v-col v-if="showContactEmail" md="9">
									<div class="d-flex align-center">
										<PhoneTemplate
											placeholder="Contcat Email"
											:disabled="pageLoading"
											id="contact-email"
											class-name="mt-0"
											v-model="opportunity.contact_email"
											:loading="pageLoading"
											@loading="($event) => (pageLoading = $event)"
											@blur="validatePhone(cindex)"
											@validity="phoneValid($event, cindex, 'contact_email')"
											@clear="opportunity.contact_email = null"
										></PhoneTemplate>
										<v-icon class="mb-3" color="red" @click="removeEmailField('contact_email')"
											>mdi-minus-circle-outline</v-icon
										>
									</div>
								</v-col>

								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Website</label>
								</v-col>
								<v-col md="9" class="mb-3">
									<TextInput
										dense
										id="website"
										hide-details
										type="text"
										placeholder="Website"
										:rules="[vrules.maxLength(opportunity.website, 'website', 30)]"
										:disabled="pageLoading"
										:loading="pageLoading"
										class="mt-0"
										v-model="opportunity.website"
									></TextInput>
								</v-col>

								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Amount </label>
								</v-col>
								<v-col md="9" class="mb-3">
									<v-text-field
										hide-details
										outlined
										type="number"
										placeholder="Amount"
										v-model="opportunity.amount"
										:disabled="pageLoading"
										:loading="pageLoading"
										v-on:keypress="limitDecimal($event, opportunity.amount)"
										v-on:paste="limitDecimal($event, opportunity.amount)"
										prepend-inner-icon="mdi-currency-usd"
									>
									</v-text-field>
								</v-col>
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Source</label>
								</v-col>
								<v-col md="9" class="mb-0">
									<v-autocomplete
										hide-details
										:items="sourceList"
										placeholder="Select Source"
										outlined
										v-model="opportunity.source"
										item-text="text"
										item-value="text"
										class="pr-8"
									>
									</v-autocomplete>
									<ManageDescriptionDialog
										customClass="mt-2 px-0"
										title="Source"
										typeId="2"
										v-on:update="getSettings"
									>
									</ManageDescriptionDialog>
								</v-col>
								<v-col md="3" class="mt-4 d-none text-end">
									<label class="fw-500 mt-1">Target date</label>
								</v-col>
								<v-col md="9" class="mb-0 d-none">
									<DatePicker
										hide-details
										clearable
										:disabled="pageLoading"
										:loading="pageLoading"
										id="Target-date"
										placeholder="Target Date"
										:min-date="minDate"
										:max-date="maxDate"
										v-model="opportunity.target_date"
									></DatePicker>
								</v-col>

								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Description</label>
								</v-col>
								<v-col md="9" class="">
									<TextAreaInput
										hide-details
										placeholder="Description"
										v-model="opportunity.description"
										:word-limit="200"
										:rows="4"
										class="mt-0"
									></TextAreaInput>
								</v-col>
								<v-col md="3" class="mt-2 text-end">
									<label class="fw-500">Internal remark </label>
								</v-col>
								<v-col md="9" class="">
									<v-checkbox
										class="ma-0"
										hide-details
										color="primary"
										v-model="opportunity.internal_check"
									></v-checkbox>
									<TextAreaInput
										v-if="opportunity.internal_check"
										hide-details
										placeholder="Remark"
										v-model="opportunity.remark"
										:word-limit="200"
										:rows="4"
									></TextAreaInput>
								</v-col>
							</v-row>
						</div>
					</v-form>
				</div>
				<template v-slot:append>
					<div class="py-3 px-5 d-flex justify-content-end border-top">
						<v-btn depressed tile class="mr-2 cancel_btn" v-on:click="$emit('close', true)">Cancel</v-btn>
						<v-btn
							tile
							depressed
							color="white--text"
							class="blue darken-4 save_btn"
							:loading="pageLoading"
							:disabled="pageLoading || !formValid"
							v-on:click="update_or_create()"
							>Save</v-btn
						>
					</div>
				</template>
			</v-navigation-drawer>
		</div>
		<ContactAddDialog
			v-if="contactDialog"
			v-on:success="contactDialog = false"
			v-on:close="contactDialog = false"
			:contact-dialog="contactDialog"
		>
		</ContactAddDialog>
		<CompanyAddDialog
			v-if="companyDialog"
			v-on:success="
				companyDialog = false;
				getCompany();
			"
			v-on:close="companyDialog = false"
			:company-dialog="companyDialog"
		>
		</CompanyAddDialog>
	</div>
</template>

<script>
import TextInput from "@/view/components/TextInput";
import ContactAddDialog from "@/view/components/ContactAddDialog";
import CompanyAddDialog from "@/view/components/CompanyAddDialog";
import TextValidateInput from "@/view/components/TextValidateInput.vue";
import { GET, QUERY, POST, PUT } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { toString } from "lodash";

import MainMixin from "@/core/mixins/main.mixin.js";
// import TagsAutoSearch from "@/view/components/TagsAutoSearch";
import objectPath from "object-path";
import MomentJS from "moment-timezone";
export default {
	mixins: [MainMixin],
	name: "Address",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		titleStatus: {
			type: String,
		},
		id: {
			type: Number,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			contactDialog: false,
			companyDialog: false,
			selectedPhoneType: "Company Phone",
			userType: null,
			phoneTypeList: [
				{ title: "Company Phone", value: "company_phone" },
				{ title: "Contact Mobile", value: "contact_phone" },
				{ title: "Contact DID", value: "did" },
			],
			selectedEmailType: "Company Email",
			selectedItem: 0,
			emailTypeList: [
				{ title: "Company Email", value: "company_email" },
				{ title: "Contact Email", value: "contact_email" },
			],
			userList: [
				// {
				// 	id: 1,
				// 	first_name: "Michaela",
				// 	last_name: "Shiow",
				// 	display_name: "michaela",
				// 	email: "michaela_sales@testing.com",
				// 	profile_img: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
				// 	users_type: "sales",
				// }
			],
			industrylist: [],
			typeId: 4,
			productList: [
				// { text: "SEO", value: "seo", type: 4 },
				// { text: "FSM", value: "fsm", type: 4 },
				// { text: "Asset Management", value: "asset-management", type: 4 },
				// { text: "Custom Soft", value: "custom-soft", type: 4 },
				// { text: "CRM", value: "crm", type: 4 },
				// { text: "Inventory", value: "inventory", type: 4 },
				// { text: "OMS", value: "oms", type: 4 },
				// { text: "DMS", value: "dms", type: 4 },
				// { text: "IT Services", value: "it-services", type: 4 },
				// { text: "VMS", value: "vms", type: 4 },
				// { text: "ID Software", value: "id-software", type: 4 },
				// { text: "Genic Cards", value: "genic-cards", type: 4 },
				// { text: "Software", value: "software", type: 4 },
			],
			sourceList: [
				// { text: "Web Enquiry", value: "web-enquiry", type: 2 },
				// { text: "Web Chat", value: "web-chat", type: 2 },
				// { text: "Email Marketer", value: "email-marketer", type: 2 },
				// { text: "Tele Call", value: "tele-call", type: 2 },
				// { text: "Google Adwords", value: "google-adwords", type: 2 },
				// { text: "FSM/AMS(Brochure Lead Form)", value: "fsm-ams", type: 2 },
				// { text: "Facebook", value: "facebook", type: 2 },
				// { text: "Referral", value: "referral", type: 2 },
				// { text: "Intern", value: "intern", type: 2 },
				// { text: "None", value: "none", type: 2 },
			],
			companylist: [],
			contactlist: [],
			minDate: MomentJS().subtract(7, "days").format("YYYY-MM-DD"),
			maxDate: MomentJS().format("YYYY-MM-DD"),
			statusList: [
				{
					id: 2,
					text: "Not Contacted",
					value: "not-contacted",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "purple",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 165,
				},
				{
					id: 3,
					text: "Follow UP",
					value: "follow-up",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "orange",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 118,
				},
				{
					id: 4,
					text: "Meeting",
					value: "meeting",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "pink",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 49,
				},
				{
					id: 5,
					text: "Quotation",
					value: "quotation",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "cyan",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 11,
				},
				{
					id: 6,
					text: "Rejected",
					value: "reject",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "red",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 148,
				},
				{
					id: 7,
					text: "Accepted",
					value: "accept",
					type: 1,
					provider_type: null,
					seo: 0,
					hosting: 0,
					domain: 0,
					status_color: "green",
					count: 0,
					child_name: null,
					cost: null,
					activation_date: null,
					renewal_basis: null,
					renewal_date: null,
					status_count: 22,
				},
			],
			searchKey: +new Date(),
			salutation_type_items: ["Mr", "Mrs"],
			showAdditionalFields: false,
			company_id: "",
			showDID: false,
			showFax: false,
			nextField: "DID",
			nextEmailField: "Contact Email",
			tId: 2,
			showContactEmail: false,
			opportunity: {
				name: null,
				assign: 0,
				opportunity: null,
				company: null,
				company_email: null,
				company_phone: null,
				contact: null,
				contact_email: null,
				contact_phone: null,
				landline: null,
				did: null,
				fax: null,
				website: null,
				status: "not-contacted",
				amount: null,
				product_type: null,
				source: null,
				target_date: null,
				description: null,
				internal_check: false,
				remark: null,
			},
		};
	},
	components: {
		TextInput,
		ContactAddDialog,
		CompanyAddDialog,
		TextValidateInput,
		// TagsAutoSearch,
		TextAreaInput: () => import("@/view/components/TextAreaInput"),
		PhoneTemplate: () => import("@/view/components/Phone"),
		DatePicker: () => import("@/view/components/DatePicker"),
		ManageDescriptionDialog: () => import("@/view/pages/leads/components/ManageDescriptionDialog"),
		// SearchAddress: () => import("@/view/components/Search-Address"),
	},
	mounted() {
		if (this.id > 0) {
			this.findOpportunity();
		}
		this.opportunity.assign = this.currentUser.id;

		this.getCompany();
		this.getContact();
		this.getUsers();
		this.getProduct();
		this.getSetting();
	},

	methods: {
		limitDecimal($event, value) {
			let string = toString(value);

			// console.log($event.keyCode); //keyCodes string
			let keyCode = $event.keyCode ? $event.keyCode : $event.which;

			// only allow number and one dot
			if (
				((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || string.indexOf(".") != -1)) ||
				string.length > 11
			) {
				// 46 is dot
				$event.preventDefault();
			}

			// restrict to 2 decimal places
			if (string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) {
				$event.preventDefault();
			}
		},
		searchCompany() {
			const inputValue = event.target.value;
			// console.log('Input value:', inputValue);
			if (inputValue && inputValue.length >= 3) {
				this.$store
					.dispatch(GET, {
						url: `find-company/${inputValue}`,
					})
					.then((response) => {
						this.companylist = response; // Assuming data is in response.data

						console.log(response);
					})
					.catch((error) => {
						console.error("Error fetching contact:", error);
					});
			}
		},
		getSetting() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `single-setting/${this.tId}`,
				})
				.then((data) => {
					if (data && data.length) {
						this.sourceList = data;
					} else {
						this.sourceList = [
							{
								text: null,
							},
						];
					}
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		findOpportunity() {
			this.id = this.id;

			this.$store
				.dispatch(GET, {
					url: `find-single-opportunity/${this.id}`,
				})

				.then((response) => {
					this.opportunity = response?.deal; // Assuming data is in response.data
					if (response?.deal?.leadproduct) {
						this.opportunity.product_type = response.deal.leadproduct.map(
							(product_type) => product_type.product_id
						);
					}
					console.log(response.deal.leadproduct, "this.opportunity.company");

					this.opportunity.company = response?.deal?.company?.id;
					this.opportunity.contact = response?.deal?.contact?.id;

					// console.log( this.opportunity.product_type,' this.opportunity.product_type')
					// this.contact.tags = response?.data[0]?.tag;
					// this.contact.company = response?.data[0]?.company?.id;

					console.log(response);
				})
				.catch((error) => {
					console.error("Error fetching contact:", error);
				});
		},
		changecontact() {
			this.$store
				.dispatch(QUERY, {
					url: `find-single-contact/${this.opportunity.contact}`,
					id: this.id,
				})
				.then((data) => {
					// this.companylist = data;
					this.opportunity.contact_phone = data?.data?.phone_number;
					this.opportunity.contact_email = data?.data?.email_address;

					console.log(data);
				})

				.catch((error) => {
					console.log({
						error,
					});
				});
			this.getCompany();
		},
		getProduct() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `single-setting/${this.typeId}`,
				})
				.then((data) => {
					if (data && data.length) {
						this.productList = data;
					} else {
						this.productList = [
							{
								text: null,
							},
						];
					}
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		updateData() {
			this.pageLoading = true;

			this.$store
				.dispatch(PUT, {
					url: `create-opportunity/${this.id}`,
					data: this.opportunity,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Opportunity updated successfully." },
					]);
					this.$emit("createSuccess", true);
					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		saveData() {
			this.pageLoading = true;
			console.log(this.opportunity, "opportunity");
			this.$store
				.dispatch(POST, { url: `create-opportunity`, data: this.opportunity })
				.then(({ data }) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Opportunity added successfully." },
					]);
					this.$refs.form.reset();
					this.$emit("createSuccess", data);

					this.$emit("close", true);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["form"]);

			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}
			if (this.id) {
				this.updateData();
			} else {
				this.saveData();
			}
		},
		chnagecompany() {
			this.$store
				.dispatch(QUERY, {
					url: `find-single-company/${this.opportunity.company}`,
					id: this.id,
				})
				.then((data) => {
					// this.companylist = data;
					this.opportunity.company_phone = data?.customer?.phone;
					this.opportunity.company_email = data?.customer?.email;

					console.log(data);
				})

				.catch((error) => {
					console.log({
						error,
					});
				});
			this.getContact();
		},
		getContact() {
			if (this.opportunity.company) {
				this.company_id = this.opportunity.company;
				this.$store
					.dispatch(QUERY, {
						url: `company-contact/${this.company_id}`,
						id: this.id,
					})
					.then((data) => {
						this.contactlist = data;
						console.log(data);
					})

					.catch((error) => {
						console.log({
							error,
						});
					});
			} else {
				this.$store
					.dispatch(GET, {
						url: `all-contact`,
					})
					.then((data) => {
						this.contactlist = data;
						console.log(data);
					})

					.catch((error) => {
						console.log({
							error,
						});
					});
			}
		},
		getCompany() {
			if (this.opportunity.contact) {
				this.contact_id = this.opportunity.contact;
				this.$store
					.dispatch(QUERY, {
						url: `contact-company/${this.contact_id}`,
						id: this.id,
					})
					.then((data) => {
						this.companylist = data;
						console.log(data);
					})

					.catch((error) => {
						console.log({
							error,
						});
					});
			} else {
				this.$store
					.dispatch(GET, {
						url: `get-company`,
					})
					.then((data) => {
						this.companylist = data;
						console.log(data);
					})

					.catch((error) => {
						console.log({
							error,
						});
					});
			}
		},

		getUsers() {
			this.$store
				.dispatch(GET, {
					url: `all-opportunity-users`,
				})
				.then((data) => {
					this.userList = data.tbody;
					console.log(data);
				})

				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		selectEmailType(emailType) {
			this.selectedEmailType = emailType;
		},
		addNewEmail() {
			if (this.selectedEmailType === "Company Email") {
				console.log("Adding new Company Email:", this.opportunity.company_email);
			} else if (this.selectedEmailType === "Contact Email") {
				console.log("Adding new Contact Email:", this.opportunity.contact_email);
			}
		},
		selectPhoneType(phoneType) {
			this.selectedPhoneType = phoneType;
		},
		addContactField() {
			if (this.nextField === "DID") {
				this.showDID = true;
			}
		},
		removeContactField(field) {
			if (field === "did") {
				this.opportunity.did = null;
				this.showDID = false;
				this.nextField = "DID";
			} else if (field === "fax") {
				this.opportunity.fax = null;
				this.showFax = false;
				this.nextField = "Fax";
			}
		},
		addEmailField() {
			if (this.nextEmailField === "Contact Email") {
				this.showContactEmail = true;
				this.nextEmailField = "Contact Email";
			}
		},
		removeEmailField(field) {
			if (field === "contact_email") {
				this.opportunity.contact_email = null;
				this.showContactEmail = false;
				this.nextEmailField = "Contact Email";
			}
		},
		contactAddDialog() {
			this.contactDialog = true;
		},
		companyAddDialog() {
			this.companyDialog = true;
		},
		getSettings() {},
		remove() {
			// const index = this.opportunity.contact.indexOf(item.id);
			this.opportunity.contact = null;
			this.opportunity.company = null;
			this.getCompany();
		},
		removecompany() {
			this.opportunity.company = null;
			this.opportunity.contact = null;
			this.getContact();
		},
		removeOpp() {
			this.opportunity.assign = null;
		},
		getValue(object, key) {
			return objectPath.get(object, key);
		},
		setAddress(e) {
			console.log({ e });

			this.customer.postal_code = e.postal_code;
			this.customer.address_line_1 = e.address1 && e.address1 != "NIL" ? e.address1 : "";
			this.customer.address_line_2 = e.address2 && e.address2 != "NIL" ? e.address2 : "";
			this.customer.unit_number = e.unit_number;
		},
		appendDisplayName() {
			const result = [];
			const { first_name, last_name } = this.opportunity;
			if (first_name) {
				result.push(first_name);
			}
			if (last_name) {
				result.push(last_name);
			}
			if (this.opportunity.company_type == "personal") {
				this.opportunity.display_name = result.join(" ");
			}

			/*    } */
			if (this.opportunity.company_type == "opportunity") {
				this.opportunity.display_name = this.opportunity.company_name;
			}
		},
		company_rules() {
			const rules = [];

			if (this.opportunity.primary) {
				rules.push(this.validateRules.required(this.opportunity.company, "display name"));
			}

			// rules.push(this.validateRules.minLength(this.opportunity.company_name, "display nam", 2));

			// rules.push(this.validateRules.maxLength(this.opportunity.company_name, "display name", 100));

			return rules;
		},
	},
};
</script>
<style>
aside.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--open.v-navigation-drawer--right.v-navigation-drawer--temporary.theme--light {
	z-index: 99 !important;
}
</style>
