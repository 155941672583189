<template>
	<Dialog :dialog="rejectDialog" :dialog-width="600" @close="$emit('close', true)">
		<template v-slot:title>Mark as Reject</template>
		<template v-slot:body>
			<v-form
				ref="claimApprovalForm"
				id="form"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="update_or_create"
			>
				<v-row>
					
					<v-col md="12" class="mb-3">
						<label class="fw-500 mb-1">Remark (optional)</label>
						<TextAreaInput
							hide-details
							dense
							style="margin-top: 0px !important"
							:disabled="pageLoading"
							:loading="pageLoading"
							placeholder="Remark"
							:word-limit="200"
							v-model="reject.remark"
						></TextAreaInput>
					</v-col>
					
				</v-row>
			</v-form>
		</template>
		<template v-slot:action>
			<v-btn
				class="cancel_btn"
				depressed
				tile
				:disabled="pageLoading"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
			<v-btn
				class="white--text save_btn"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="red darken-4"
				tile
				v-on:click="update_or_create('reject')"
			>
				Mark as Reject
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import { POST } from "@/core/services/store/request.module";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import Dialog from "@/view/components/Dialog";
import TextAreaInput from "@/view/components/TextAreaInput";
export default {
	name: "contact-template",
	title: "Contact Template",
	props: {
		rejectDialog: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			reasonList: [],
			reject: {
				id: 0,
				reason: null,
				remark: null,
			},
		};
	},

	components: {
		Dialog,
		TextAreaInput,
	},

	methods: {
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},

		update_or_create(data) {
			console.log(data);
			const formErrors = this.validateForm(this.$refs["claimApprovalForm"]);
			if (formErrors.length) {
				if (!this.remark || this.remark == "null") {
					this.$store.commit(SET_ERROR, [{ model: true, message: `Remark is required!` }]);
					return false;
				}
			}
			let formData = new FormData();
			formData.append("status", "reject");
			formData.append("remark", this.reject.remark ? this.reject.remark : "");
			for (let i = 0; i < this.files?.length; i++) {
				if (this.files && this.files[i] && this.files[i].file) {
					formData.append(`file[${i}][file]`, this.files[i].file);
					formData.append(`file[${i}][name]`, this.files[i].name);
				}
			}

			// 	//this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
			// 	//return false;
			// }

			// let formData = new FormData();
			// formData.append("amount", this.status_remark.amount ? this.status_remark.amount : 0);
			// formData.append(
			// 	"drop_status",
			// 	this.status_remark.drop_status ? this.status_remark.drop_status : null
			// );
			// formData.append("drop_value", this.status_remark.drop_value ? this.status_remark.drop_value : 0);
			// formData.append("remark", this.status_remark.remark ? this.status_remark.remark : "");
			// for (let i = 0; i < this.files?.length; i++) {
			// 	if (this.files && this.files[i] && this.files[i].file) {
			// 		formData.append(`file[${i}][file]`, this.files[i].file);
			// 		formData.append(`file[${i}][name]`, this.files[i].name);
			// 	}
			// }

			// if (this.status_remark.drop_status == "Approved") {
			// 	if (this.status_remark.amount > this.summaryData[0].balance) {
			// 		const _error = [
			// 			{
			// 				message: "Amount is not available",
			// 				model: true,
			// 				timeout: 2000,
			// 			},
			// 		];
			// 		this.$store.commit(SET_ERROR, _error);
			// 		return false;
			// 	}
			// }
			// if (this.status_remark.drop_status == "Rejected") {
			// 	this.status_remark.amount = this.requestedAmount;
			// }

			// for (let i = 0; i < this.files?.length; i++) {
			// 	if (this.files && this.files[i] && this.files[i].file) {
			// 		formData.append(`file[${i}][file]`, this.files[i].file);
			// 		formData.append(`file[${i}][name]`, this.files[i].name);
			// 	}
			// }

			this.pageLoading = true;
			this.$store
				.dispatch(POST, { url: `status-remark-opportunity/${this.$route.params.id}`, data: formData }) //this.status_remark
				.then(({ data }) => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success! Status updated successfully." },
					]);
					this.status_remark = {};
					this.rejectDialog = false;

					this.$refs.claimApprovalForm.reset();
					this.$emit("close", false);
					this.$emit("success", data);
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
			// this.$store
			// 	.dispatch(POST, { url: `status-remark-opportunity/${this.$route.params.id}`, data: {
			// 		data: data,
			// 		}, }) //this.status_remark
			// 	.then(({ data }) => {
			// 		this.$store.commit(SET_MESSAGE, [
			// 			{ model: true, message: "Success! Status updated successfully." },
			// 		]);
			// 		this.status_remark = {};
			// 		this.acceptDialog = false;

			// 		// this.$refs.claimApprovalForm.reset();
			// 		this.$emit("close", false);
			// 		this.$emit("success", data);
			// 	})
			// 	.catch((error) => {
			// 		console.log({ error });
			// 	})
			// 	.finally(() => {
			// 		this.pageLoading = false;
			// 	});
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},

		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
	},
};
</script>
