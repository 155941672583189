<template>
	<Dialog :dialog="completeDialog" :dialog-width="600" @close="$emit('close')">
		<template v-slot:title>
			Complete {{ type }}
			<pre></pre>
		</template>

		<template v-slot:body>
			<v-row class="delete-dialog">
				<v-col md="2" class="py-0 text-right my-auto">
					<span class="svg-icon svg-icon-lg delete-confirm-icon">
						<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
					</span>
				</v-col>
				<v-col md="10" class="py-0 my-auto">
					<p class="btx-p m-0">
						Mark as Complete <span class="text-capitalize">{{ type }}</span>
						<span class="red--text lighten-1 font-level-1-bold"> {{ completeText }}</span>
					</p>
					<!-- <p class="btx-p m-0" v-if="datas">
						Mark as Complete   <span class="text-capitalize"></span>
						<span class="red--text lighten-1 font-level-1-bold">  {{ count }} Reminders</span>

					</p> -->
				</v-col>
				<v-col md="10" class="py-0 my-auto">
					<p class="btx-p m-0">
						<template>
							<v-col md="12" class="py-0">
								<div class="d-flex">
									<RadioInput
										row
										hide-details
										v-model="thisOrAll"
										@change="radiocheck(thisOrAll)"
										id="reminder-this"
										:items="reminderItems"
									></RadioInput>
									<span v-if="datas" class="red--text lighten-1 font-level-1-bold mt-4"
										>({{ count }}) Reminders
									</span>
								</div>
							</v-col>
						</template>
					</p>
				</v-col>
				<!-- <v-col v-if="deleteNote" md="12" class="pb-0 my-auto">
					<em>Note: {{ deleteNote }}</em>
				</v-col> -->
			</v-row>
		</template>
		<template v-slot:action>
			<slot name="extra-btn"></slot>
			<v-btn
				class="white--text"
				:loading="deleteButton"
				:disabled="deleteButton"
				depressed
				color="red lighten-1"
				tile
				v-on:click="deleteRow()"
			>
				Yes! Complete
			</v-btn>
			<v-btn depressed tile :disabled="deleteButton" v-on:click="$emit('close', true)">
				No, Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog";
//import ApiService from "@/core/services/api.service";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import RadioInput from "@/view/components/RadioInput";
import { PATCH } from "@/core/services/store/request.module";

export default {
	name: "delete-template",
	title: "Delete Template",
	props: {
		type: {
			type: String,
			default: null,
		},
		completeUrl: {
			type: String,
			default: null,
		},
		completeText: {
			type: String,
			default: null,
		},
		completeNote: {
			type: String,
			default: null,
		},
		count: {
			type: String,
			default: null,
		},
		completeDialog: {
			type: Boolean,
			default: false,
		},
		dataObject: {
			type: [Object,Array],
			default: null,
		},
	},
	data() {
		return {
			deleteButton: false,
			datas:null,
			reminderItems: [
				{
					label: "This Reminder",
					value: "this",
				},
				{
					label: "All This Type",
					value: "all",
				},
			],
			thisOrAll: "this",
		};
	},
	components: {
		Dialog,
		RadioInput,
	},
	methods: {
		radiocheck(data) {
			if (data == "all") {
				this.datas = 1;
			} else {
				this.datas = null;
			}
		},
		deleteRow() {
			this.deleteButton = true;
			this.$store
				.dispatch(PATCH, {
					url: this.completeUrl,
					data: {
						status: 3,
						this_or_all: this.thisOrAll,
					},
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Reminder completed successfully." },
					]);
					this.$emit("success", true);
					this.$emit("close", true);
					//remind.status_marked = 'complete';
					//this.completeDialog = false;
					this.deleteButton = false;
					this.$emit("completeSuccess", this.dataObject);
				})
				.catch((error) => {
					console.log({ error });
				});
		},
	},
};
</script>
<style scoped>
.v-input--radio-group.v-input--radio-group--row .v-radio.theme--light.v-item--active {
	margin-right: 0px;
}
</style>
